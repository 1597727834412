<template>
  <div class="professions-profile-container">
    <button
      class="btn btn-outline-blue mb-3"
      @click="addProfession"
      v-if="user && (user.isAdmin || user.isManagingAdmin)"
    >
      <icon type="plus" /> Add Profession
    </button>
    <alert v-if="professions.isLoading" class="light-shadow mt-4" />
    <alert
      v-if="!professions.isLoading && !professions.data.length"
      class="light-shadow mt-4"
      :hideLoader="true"
      >No professions added yet.</alert
    >
    <div
      class="
        card
        theme-card
        border-lg-0
        u-p-0-force
        mobile-tablet-no-card
        table-container
      "
      v-if="professions.data.length > 0 && !professions.isLoading"
    >
      <table
        class="
          table
          mb-0
          table-striped
          responsive-mobile-tablet-table
          border border-lg-0
        "
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>College #</th>
            <th>Rate</th>
            <th>Practitioner</th>
            <th>Regulated</th>
            <th>Created</th>
            <th
              v-if="user && (user.isAdmin || user.isManagingAdmin)"
              class="text-center"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in professions.data" :key="p.id">
            <td><b class="mobile-tablet-only mr-2">Name:</b>{{ p.name }}</td>
            <td>
              <b class="mobile-tablet-only mr-2">College #:</b
              >{{ p.collegeNumber }}
            </td>
            <td><b class="mobile-tablet-only mr-2">Rate:</b>${{ p.rate }}</td>
            <td class="text-center">
              <b class="mobile-tablet-only mr-2">Practitioner:</b>
              <icon
                :type="p.isPractitioner ? 'check' : 'times'"
                :class="p.isPractitioner ? 'text-success' : 'text-danger'"
              />
            </td>
            <td class="text-center">
              <b class="mobile-tablet-only mr-2">Regulated:</b>
              <icon
                :type="p.isRegulated ? 'check' : 'times'"
                :class="p.isRegulated ? 'text-success' : 'text-danger'"
              />
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Created:</b
              >{{ p.created_at | date("YYYY/MM/DD") }}
            </td>
            <td
              v-if="user && (user.isAdmin || user.isManagingAdmin)"
              class="text-center td-actions"
            >
              <button
                class="btn btn-sm btn-black p-2 border-0 mr-2"
                @click="openUpdateProfessionModal(p)"
              >
                <b class="mobile-tablet-only mr-2">Edit</b>
                <i class="fa fa-edit actionIcons"></i>
              </button>
              <button
                class="btn btn-sm btn-danger p-2 border-0"
                @click="deleteProfession(p)"
              >
                <b class="mobile-tablet-only mr-2">Delete</b>
                <i class="fa fa-trash actionIcons" v-if="!p.deleting"></i>
                <i class="fas fa-spin fa-circle-notch" v-if="p.deleting"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <modal
      class="update-profession-modal"
      name="update-profession-modal"
      transition="pop-out"
      :height="354"
    >
      <div class="position-relative">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button
            class="btn p-0 pr-3 pt-3 border-0"
            @click="closeUpdateProfessionModal"
          >
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="">
          <div class="card-body">
            <form @submit.prevent="UpdateProfession" validate>
              <select-control
                v-model="selectedProfession.id"
                :options="filteredProfession"
                :required="true"
                disabled
                labelClass="col-md-2 col-12"
                wrapClass="align-items-center"
                >Profession</select-control
              >
              <input-control
                v-model="selectedProfession.collegeNumber"
                type="horizontal"
                v-if="regulated"
                :required="true"
                wrapClass="align-items-center"
                >College Number</input-control
              >
              <div class="form-group row align-items-center">
                <label class="col-4 col-md-2 col-form-label mr-3"
                  >Rate Type</label
                >
                <div class="custom-control custom-radio mr-4">
                  <input
                    type="radio"
                    id="hour"
                    value="hour"
                    v-model="selectedProfession.rateType"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="hour">Hourly</label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="flat"
                    value="flat"
                    v-model="selectedProfession.rateType"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="flat">Flat</label>
                </div>
              </div>
              <input-control
                control="number"
                v-model="selectedProfession.rate"
                type="horizontal"
                :required="true"
                step="0.01"
                min="0"
                wrapClass="align-items-center"
                >Rate</input-control
              >
              <div class="col-12 p-0 d-flex justify-content-center">
                <save :saving="professions.isSaving">Update Profession</save>
              </div>
            </form>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "UserProfession",
  data() {
    return {
      selectedProfession: {},
    };
  },
  computed: {
    ...mapState({
      targetUser: (state) => state.Profile.user,
      professions: (state) => state.Profile.professions,
      user: (state) => state.auth.user,
      provider: (state) => state.providers.provider,
    }),
    regulated: function () {
      if (this.selectedProfession) {
        if (!this.selectedProfession.isRegulated) {
          this.clearCollegeNumber();
        }
        return this.selectedProfession.isRegulated;
      }
      return false;
    },
    filteredProfession: function () {
      const array = [];
      this.professions.data.filter((p) => {
        array.push({ label: p.name, value: p.id });
      });
      return array;
    },
  },
  mounted() {
    this.getUserProfessions(this.targetUser.id);
  },
  methods: {
    ...mapActions({
      getUserProfessions: "professions/getUserProfessions",
      attachProfession: "professions/attachProfessionToUser",
      detachProfession: "professions/detachProfessionFromUser",
    }),
    addProfession: function () {
      this.$router.push({
        name: "admin.profession.create",
        params: { id: this.targetUser.id },
      });
    },
    openUpdateProfessionModal(selectedProfession) {
      this.selectedProfession = Object.assign({}, selectedProfession);
      this.$modal.show("update-profession-modal");
    },
    deleteProfession(profession) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            profession.deleting = true;
            vm.$forceUpdate();
            vm.detachProfession({
              ...profession,
              user_id: vm.targetUser.id,
            }).then((success) => {
              profession.deleting = false;
              vm.$forceUpdate();
              if (success) {
                vm.getUserProfessions(vm.targetUser.id);
              }
            });
          }
        });
    },
    closeUpdateProfessionModal() {
      this.$modal.hide("update-profession-modal");
    },
    clearCollegeNumber: function () {
      this.selectedProfession.collegeNumber = null;
    },
    UpdateProfession: function () {
      this.attachProfession({
        id: this.selectedProfession.id,
        profession: `${this.selectedProfession.id}`,
        collegeNumber: this.selectedProfession.collegeNumber,
        user: this.targetUser.id,
        rate: this.selectedProfession.rate,
        rateType: this.selectedProfession.rateType,
      })
        .then(() => {
          this.closeUpdateProfessionModal();
          this.getUserProfessions(this.targetUser.id);
        })

        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text: (err.data || {}).message || "Something went wrong...",
              icon: "error",
            });
          }
          this.saving = false;
          this.closeUpdateProfessionModal();
        });
    },
  },
};
</script>

<style lang="scss">
.update-profession-modal {
  width: 100%;
  margin: 0 auto;
}
.professions-profile-container {
  margin-top: 2.5rem !important;
}

@media (max-width: 1024px) {
  .u-p-0-force {
    padding: 0 !important;
  }
}
</style>
